<template>
<!--  <div id="login">-->
<!--    <div class="container">-->
      <b-row>
        <div class="col-sm-12 col-lg-6 offset-lg-3">
          <b-form-group
              id="fieldset-1"
              label="Введите пароль"
              label-for="input-1"
              valid-feedback=""
              :invalid-feedback="error.message"
              :state="!error.isError"
          >
            <b-form-input id="input-1" v-model="password" :state="!error.isError" trim></b-form-input>
          </b-form-group>
          <b-button block @click.prevent="auth()">Войти</b-button>
        </div>
      </b-row>
<!--    </div>-->
<!-- -->
<!--  </div>-->
</template>

<style lang="scss">
</style>

<script>
export default {
  name: "login-page",
  data: () => ({
    password: "",
    error: { isError: null, message: "" }
  }),
  async mounted() {
    // this.password = this.$cookies.get("dildo-pass");
    const tolken = this.$cookies.get("dildo-token");
    if (tolken) this.$router.push({ path: "/" });
  },
  methods: {
    routingUser(role) {
      console.log('routing to', role)
      switch (role) {
        case 'quiz':
          this.$router.push({path: "/quiz"})
          break
        case 'glava':
          this.$router.push({path: "/global-products"})
          break
        case 'acuvue':
          this.$router.push({path: "/acuvue-table"})
          break
        case 'feedbacks':
          this.$router.push({path: "/wb-feedbacks"})
          break

        default:
          this.error = {
            isError: true,
            message: "Ваша учетная запись не подтверждена, дождитесь проверки"
          };
          break

      }
    },
    async auth() {
      this.error = { isError: false, message: "" };
      fetch(`${process.env.VUE_APP_SERVER_API}/auth/login`, {
        method: "POST",
        mode: "cors",
        referrerPolicy: "no-referrer-when-downgrade",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify({ password: this.password }) // body data type must match "Content-Type" header
      })
      .then(resp => resp.json())
          .then(resp=>{
            if (resp.err) throw resp.err
            this.$cookies.remove("dildo-token")
            this.$cookies.remove("dildo-role")
            this.$cookies.set("dildo-token", resp.token)
            this.$cookies.set("dildo-role", resp.role)

            this.routingUser(resp.role)
          })
      .catch(err => {
        this.$cookies.remove("dildo-role")
        this.$cookies.remove("dildo-token")
        this.error = { isError: true, message: err };
      })

    }
  },
};
</script>